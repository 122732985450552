<template>
  <section>
    <h1>About</h1>
    <p>Hi, I'm Abraão Pinto, developer, enthusiast for web and mobile development technologies. This project was built with <a
            href="https://vuejs.org/"
            target="_blank"
            rel="noopener"
          >VueJs</a> and some of the functions used, can be found in the repository of simple and useful functions or <a
            href="https://github.com/abraaopinto/useful-simple-functions"
            target="_blank"
            rel="noopener"
          >useful-simple-functions</a>.<br>
          If this site has impacted you positively. you can reciprocate by sharing it with friends. Or follow me<a href="https://twitter.com/abraaodev?ref_src=twsrc%5Etfw" >@abraaodev</a> on twitter.
          More about me <a
            href="https://abraao.dev"
            target="_blank"
            rel="noopener"
          >abraao.dev</a>.</p>
        <p>👋</p>
  </section>
</template>
<style lang="stylus" scoped>
section
  padding 1em

p
  font-size clamp(1em, 1em+1vw, 1.6em)
  line-height 1.5em
  text-align justify
</style>
